<template>
  <div>
    <cc-datatable ref="client-datatable" :filterValues="[]">
      <template #table-caption>
        <h4>Grupos econômicos cadastrados na plataforma</h4>
      </template>
    </cc-datatable>
  </div>
</template>
<script>
import CcDatatable from '@/views/common/crud/components/Datatable.vue'

export default {
  components: {
    CcDatatable,
  }
}
</script>
<style lang="scss">
#my-table th[aria-colindex="1"] {
  div {
    float: left !important;
  }
}

#my-table td[aria-colindex="1"] {
  span {
    float: left !important;
    text-decoration: underline;
  }
}
</style>